<template>
  <div class="list-main">
    <div style="width: 1250px;display: flex;justify-content: space-between">
      <div style="width: 300px;background: #ffffff;border-left: 1px solid #dddddd;border-right: 1px solid #dddddd;">
        <div :class="check==='trans-list'?'list-item-active':'list-item'"
             @click="()=>{this.check='trans-list';this.getList();}">
          <img src="../assets/trans-list.png" alt="" class="list-item-img"/>
          翻译列表
        </div>
        <div :class="check==='collection-list'?'list-item-active':'list-item'"
             @click="()=>{this.check='collection-list';this.getCollList();}">
          <img src="../assets/collection-list.png" alt="" class="list-item-img"/>
          收藏列表
        </div>
      </div>
      <div style="width: 900px;background: #ffffff;display: flex;justify-content: center">
        <div v-show="check==='trans-list'" style="width: 869px">
          <div class="list-main-content">
            <el-table
                @filter-change="filterStatus"
                @sort-change="sortTime"
                :default-sort="{prop: 'datetime', order: 'descending'}"
                :header-align="'center'"
                :row-style='({row, rowIndex})=>{
            if(rowIndex%2!==0){
              return{
                "background": "rgba(245, 245, 245, 1)"
              }
            }
          }'
                :cell-style='({row, column, rowIndex, columnIndex})=>{
            if(rowIndex%2!==0){
              return{
                "border-bottom":"0px",
                "font-size":" 14px",
                "font-family": "Source Han Sans CN",
                "font-weight": "400",
                "color": "#333333",
                "background": "rgba(245, 245, 245, 1)"
              }
            }
            return {
                "border-bottom":"0px",
                "font-size":" 14px",
                "font-family": "Source Han Sans CN",
                "font-weight": "400",
                "color": "#333333",
            }
          }'
                :header-cell-style="({ row, column, rowIndex, columnIndex })=>{
            if (rowIndex === 0) {
              return 'text-align: center;border-bottom: 0px;background: rgba(245, 245, 245, 1);font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;'
            }
            return 'border-bottom: 0px;'
          }"
                :stripe='true'
                ref="filterTable"
                :data="tableData"
                style="width: 100%">
              <el-table-column
                  type="index"
                  label="序号"
                  prop="id"
                  width="80"
                  align="center"
                  :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="题名"
                  width="300"
              >
                <template slot-scope="scope">
                  <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: scope.row.docId,
                        trans:trans
                      }}">
                    <div :title="scope.row.name"
                         style="color:#666666 ;width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                      {{ scope.row.name }}
                    </div>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  prop="datetime"
                  width="100"
                  :sortable='"custom"'
                  label="时间">
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="状态"
                  width="100"
                  column-key="status"
                  :filter-multiple="false"
                  align="center"
                  :filters="[ { text: '已翻译', value: '已翻译' }, { text: '未翻译', value: '未翻译' }]"
                  filter-placement="bottom-end">
                <template slot-scope="scope">
                  <el-progress v-show="scope.row.status!=='已翻译'&&scope.row.number!==100"
                               :percentage="scope.row.number?scope.row.number:0" :format="format">
                  </el-progress>
                  <span style="color: rgba(25, 70, 135, 1)"
                        v-show="scope.row.status === '已翻译'||scope.row.number===100">已翻译</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="pageNum"
                  label="页数"
                  width="70"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="option"
                  label="操作"
                  align="center"
              >
                <template slot-scope="scope">
                  <div style="cursor: pointer;display:flex;align-items: center;justify-content: space-around;">
                    <div @click="read(scope.row)" class="read"></div>
                    <div @click="sendMail(scope.row)" class="mail"></div>
                    <el-dropdown @command="handleOrgCommand($event,scope.row,'tran')">
                      <div class="down"></div>
                      <el-dropdown-menu slot="dropdown"
                                        style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                        <el-dropdown-item
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                            command="pdf">PDF
                        </el-dropdown-item>
                        <el-dropdown-item
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                            command="docx">WORD
                        </el-dropdown-item>
                        <el-dropdown-item
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                            command="commentsone">原文批准
                        </el-dropdown-item>
                        <el-dropdown-item
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                            command="commentstwo">译文批准
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <div @click="del(scope.row)" class="del"></div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="page" style="display: flex;justify-content: flex-end;margin-top: 30px;">
              <el-pagination
                  @current-change="handleCurrentChange"
                  background
                  layout="prev, pager, next"
                  :total="count">
              </el-pagination>
            </div>
          </div>
          <div v-if="downLoadList&&downLoadList.length>0" class="downs" style="
    height: 50px;
          z-index: 100;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 10px;
          right: 0;
          border-radius: 6px;">
            <div style="height: 50px;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
              <el-progress v-for="(item,index) in  downLoadList" :key="item.id"
                           style="padding-left: 10px;padding-right: 10px"
                           :stroke-width="2" :color="'#231815'"
                           status="success" :width="35" type="circle"
                           :percentage="50">
              </el-progress>
            </div>
          </div>
        </div>
        <div v-show="check==='collection-list'" style="width: 869px">
          <div class="list-main-content">
            <el-table
                @filter-change="filterStatus"
                @sort-change="sortTime"
                :header-align="'center'"
                :row-style='({row, rowIndex})=>{
                  if(rowIndex%2!==0){
                    return{
                      "background": "rgba(245, 245, 245, 1)"
                    }
                  }
                }'
                :cell-style='({row, column, rowIndex, columnIndex})=>{
                    if(rowIndex%2!==0){
                      return{
                        "border-bottom":"0px",
                        "font-size":" 14px",
                        "font-family": "Source Han Sans CN",
                        "font-weight": "400",
                        "color": "#333333",
                        "background": "rgba(245, 245, 245, 1)"
                      }
                    }
                    return {
                        "border-bottom":"0px",
                        "font-size":" 14px",
                        "font-family": "Source Han Sans CN",
                        "font-weight": "400",
                        "color": "#333333",
                    }
                  }'
                :header-cell-style="({ row, column, rowIndex, columnIndex })=>{
                  if (rowIndex === 0) {
                    return 'text-align: center;border-bottom: 0px;background: rgba(245, 245, 245, 1);font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;'
                  }
                  return 'border-bottom: 0px;'
                }"
                :stripe='true'
                ref="filterTable"
                :data="collTableData"
                style="width: 100%">
              <el-table-column
                  type="index"
                  label="序号"
                  prop="id"
                  width="80"
                  align="center"
                  :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="题名"
                  width="390"
              >
                <template slot-scope="scope">
                  <router-link target="_blank"
                               :to="{name:scope.row.collection==='Periodical'?'Document':'MagazineInfo',query: {
                        collection: scope.row.collection,
                        id: scope.row.doc_id,
                        trans:trans
                      }}">
                    <div :title="scope.row.title"
                         style="cursor:pointer;width: 100%;text-overflow: ellipsis;
                       white-space: nowrap;overflow: hidden;color: #666666">
                      {{ scope.row.title }}
                    </div>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  prop=""
                  width="150"
                  label="作者">
                <template slot-scope="scope">
                  <div :title="scope.row.creator"
                       style="color: #666666;width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                    {{ scope.row.creator }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="create_time"
                  width="160"
                  label="时间">
              </el-table-column>
              <el-table-column
                  prop="option"
                  label="操作"
                  align="center"
              >
                <template slot-scope="scope">
                  <div style="cursor: pointer;display:flex;align-items: center;justify-content: space-around;">
                    <div @click="delColl(scope.row.id)"
                         style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                      删除
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="page" style="display: flex;justify-content: flex-end;margin-top: 30px;">
              <el-pagination
                  @current-change="handleCollCurrentChange"
                  background
                  layout="prev, pager, next"
                  :total="collCount">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  checkStatusRequest,
  sendEmailRequest,
  deleteRequest,
  downloadRequest,
  listRequest,
  searchServiceClient,
  collListRequest, delCollRequest
} from "@/tools/search-api";
import jwt from "jwt-decode";

export default {
  name: "List",
  data() {
    return {
      tableData: [],
      fileStatus: '全部',
      sort: "created_time",
      sortType: "DESC",
      currentPage: 1,
      pageSize: 10,
      count: 0,
      downLoadList: [],
      isShowTransDoc: undefined,
      clas: '',
      downLoadItem: undefined,
      radio: 'pdf',
      comment: '',
      trans: true,
      transTimer: undefined,
      isOk: false,
      oldToken: '',
      checkLogin: undefined,
      check: 'trans-list',

      collCurrentPage: 1,
      collPageSize: 10,
      collTableData: [],
      collCount: 0,
    }
  },
  created() {
    this.getList()
    this.getCollList()
    let isShowTransDoc = localStorage.getItem("isShowTransDoc")
    if (isShowTransDoc) {
      this.isShowTransDoc = isShowTransDoc
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    }
  },
  destroyed() {
    clearInterval(this.transTimer)
    clearInterval(this.checkLogin)
  },
  methods: {
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    handleCollCurrentChange(val) {
      this.collCurrentPage = val
      this.getCollList()
    },
    checkStatus() {
      if (this.isShowTransDoc) {
        let docs = JSON.parse(this.isShowTransDoc)
        let isOk = true
        for (let i = 0; i < docs.length; i++) {
          this.tableData.forEach(data => {
            if (data.docId === docs[i].id) {
              data.number = docs[i].number
            }
          })
          if (docs[i].number === 100) {
            continue
          }
          isOk = false
          let Range = 5 - 1;
          let Rand = Math.random();
          let num = 1 + Math.round(Rand * Range) + parseInt(docs[i].number || 0)
          docs[i].number = num > 99 ? 99 : num
          let request = checkStatusRequest
          request.setDocId(docs[i].id)
          let token = this.$cookies.get('token')
          if (!token) {
            token = localStorage.getItem('token');
          }
          searchServiceClient.checkStatus(request, {
            ['token']: token
          }).then(res => {
            if (res.getStatus()) {
              docs[i].number = 100;
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            } else {
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            }
          }).catch(err => {
            console.log(err)
          })
        }
        if (isOk) {
          clearInterval(this.transTimer)
          localStorage.removeItem("isShowTransDoc")
          this.isShowTransDoc = undefined;
          this.isOk = isOk
        }
      } else {
        clearInterval(this.transTimer)
      }
    }
    ,
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`;
    }
    ,
    handleOrgCommand(command, item, clas) {
      this.downLoadList.push(item)
      this.clas = clas
      this.downLoadItem = item
      this.radio = command !== 'pdf' && command !== 'docx' ? 'docx' : command
      this.comment = command
      this.downloadDoc()
    },
    downloadDoc() {
      let request = downloadRequest;
      request.setId(this.downLoadItem.docId);
      request.setType(this.radio)
      request.setClasses(this.clas)
      request.setComment(this.comment)
      request.setName(this.downLoadItem.name)
      const that = this;
      const TOKEN_KEY = 'token';
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let stream = searchServiceClient.download(request, {
        [TOKEN_KEY]: token
      });
      let flag = 0;
      let content = "";
      let title = "";
      let type = "";
      let message = '';
      stream.on('data', (res) => {
        message = res.getMessage()
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() && flag === 0) {
          title = res.getTitle();
          type = res.getType();
        }
        if (!res.getStatus() && flag === 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: res.getMessage()
          });
          that.centerDialogVisible = false;
          return;
        }
        flag++;
        content += res.getContent_asB64();
      });
      stream.on('end', (end) => {
        if (message === 'login') {
          return
        }
        if (message === 'login-person') {
          return;
        }
        let aLink = document.createElement('a');
        let blob = that.base64ToBlob(content, type);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = title;
        aLink.href = window.URL.createObjectURL(blob)
        aLink.click();
        that.centerDialogVisible = false;
        let downLoadList = []
        that.downLoadList.forEach(item => {
          debugger
          if (item.docId === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
      });
      stream.on('error', (err) => {
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.docId === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
        that.centerDialogVisible = false;
        if (err && err.code !== 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: '下载文档失败，请稍后再试！'
          })
        }
      });
    },
    base64ToBlob(code, type) {
      let bstr = atob(code)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: type});
    }
    ,
    sendMail(row) {
      let request = sendEmailRequest;
      request.setDocId(row.id)
      const TOKEN_KEY = 'token';
      searchServiceClient.sendEmail(request, {
        [TOKEN_KEY]: localStorage.getItem(TOKEN_KEY)
      }).then(res => {
        debugger
        let status = res.getStatus();
        let message = res.getMessage();
        if (status) {
          if ("send" === message) {
            this.$message.success("翻译后的文献即将发送至您的邮箱，请注意查收!")
            return
          }
          this.$message.success("文献翻译完成后将发送至您的邮箱，请注意查收！")
          return
        }
        if ("login" === message) {
          this.$message.warning("登录过期，请重新登录")
          this.showIframe(process.env.VUE_APP_WEB_LOGIN_URL)
          return
        }
        if ("no_param" === message) {
          this.$message.error("参数有误，请重新操作")
          return
        }
        if ("add_email" === message) {
          this.$message({
            message: '您还未设置邮箱，请前往个人中心设置，即将跳往个人中心',
            type: 'warning',
            duration: 2000,
            onClose: () => {
              window.open("https://user.wesitrans.com/account", '_blank')
            }
          });
          return
        }
        if ("success" === message) {
          this.$message.success("翻译后的文献已发送至您的邮箱，请注意查收！")
          return
        }
        if ("sending" === message) {
          this.$message.success("翻译后的即将已发送至您的邮箱，请注意查收！")
          return
        }
        if ("status" === message) {
          this.$message.warning("当前文献不支持发送邮件")
          return
        }
        this.$message.warning("未知原因，请稍后重试")
      }).catch(err => {
        if (err && err.code === 5) {
          this.$router.push("/index")
          this.showIframe(process.env.VUE_APP_WEB_LOGIN_URL)
        }
      });
    },
    delColl(id) {
      let request = delCollRequest
      request.setId(id)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      const that = this;
      searchServiceClient.delColl(request, {
        ['token']: token
      }).then(res => {
        let status = res.getStatus();
        if (status) {
          this.$message.success(res.getMessage())
          this.getCollList()
          return
        }
        let message = res.getMessage();
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        this.$message.error(message)
      }).catch(err => {
        this.$message.error("删除数据异常，请稍后再试！")
      })
    },
    del(item) {
      let request = deleteRequest
      request.setId(item.id)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      const that = this;
      searchServiceClient.delete(request, {
        ['token']: token
      }).then(res => {
        let status = res.getStatus();
        if (status) {
          this.getList()
          return
        }
        this.$message.error("删除数据异常，请重试！")
      }).catch(err => {
        this.$message.error("删除数据异常，请稍后再试！")
      })
    }
    ,
    read(item) {
      this.$router.push({
        name: "ReadView",
        query: {
          collection: "Periodical",
          id: item.docId
        }
      })
    }
    ,
    getCollList() {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let request = collListRequest
      request.setCurrentPage(this.collCurrentPage)
      request.setPageSize(this.collPageSize)
      const that = this;
      searchServiceClient.collList(request, {
        ['token']: token
      }).then(res => {
        let status = res.getStatus();
        if (status === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (status === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        let count = res.getCount();
        let collList = res.getCollList();
        this.buildCollDatalist(collList);
        that.collCount = count
      }).catch(err => {
        this.$message.error("查询失败请稍后再试！")
      })
    },
    buildCollDatalist(collList) {
      this.collTableData = [];
      const that = this;
      collList.forEach((document, index) => {
        const data = {
          id: document.getId(),
          title: document.getTitle(),
          creator: document.getCreator(),
          create_time: document.getCreateTime(),
          doc_id: document.getDocId(),
          collection: document.getCollection(),
        };
        that.collTableData.push(data);
      })
    },
    getList() {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let request = listRequest
      request.setFileStatus(this.fileStatus)
      request.setSort(this.sort)
      request.setSortType(this.sortType)
      request.setCurrentpage(this.currentPage)
      request.setPagesize(this.pageSize)
      const that = this;
      searchServiceClient.list(request, {
        ['token']: token
      }).then(res => {
        let status = res.getStatus();
        if (status === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (status === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        let count = res.getCount();
        let documentsList = res.getDocumentsList();
        this.buildDatalist(documentsList);
        that.count = count
      }).catch(err => {
        this.$message.error("查询失败请稍后再试！")
      })
    }
    ,
    buildDatalist(documentsList) {
      this.tableData = [];
      const that = this;
      documentsList.forEach((document, index) => {
        const to = "自动检测"
        const from = "中文（简体）"
        const language = from + '-' + to;
        const pageNum = document.getPageNum() === '' ? '-' : document.getPageNum();
        let status = document.getStatus();
        let number = 0;
        const data = {
          id: document.getId(),
          name: document.getName(),
          status: status,
          language: language,
          pageNum: pageNum,
          number: number,
          datetime: document.getCreateTime(),
          refundMode: document.getRefundMode(),
          docId: document.getDocId()
        };
        this.tableData.push(data);
      })
    }
    ,
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    }
    ,
    indexMethod(index) {
      return ((this.currentPage - 1) * this.pageSize) + index + 1;
    }
    ,
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    }
    ,
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    }
    ,
    sortTime({column, prop, order}) {
      switch (order) {
        case "descending":
          order = "DESC"
          break;
        case "ascending":
          order = "ASC"
          break;
        default:
          order = "DESC"
      }
      this.sortType = order
      this.currentPage = 1
      this.pageSize = 10
      this.getList()
    }
    ,
    filterStatus(obj) {
      for (let k in obj) {
        let vals = obj[k]
        if (vals.length === 0) {
          this.fileStatus = '全部'
          continue
        }
        this.fileStatus = vals[0]
      }
      this.currentPage = 1
      this.pageSize = 10
      this.getList()
    }
    ,
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  }
}
</script>

<style scoped lang="less">
.list-item-active {
  cursor: pointer;
  width: 295px;
  height: 45px;
  margin-top: 38px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #E83428;
  display: flex;
  align-items: center;
  border-left: 5px solid #E83428;
  background: #F5F5F5;

  .list-item-img {
    margin-left: 43px;
    margin-right: 8px;
  }
}

.list-item {
  cursor: pointer;
  width: 300px;
  height: 45px;
  margin-top: 38px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;

  .list-item-img {
    margin-left: 48px;
    margin-right: 8px;
  }
}

.list-main {
  width: 100%;
  display: flex;
  background: #F5F5F5;
  min-height: 800px;
  justify-content: center;
  border-top: 1px solid #DDDDDD;


  /deep/ .isShowTransDoc {
    .el-progress-bar {
      padding-right: 50px;
      width: 117%;
      margin-right: -54px;
      box-sizing: border-box;
    }

    .el-progress__text {
      margin-top: -35px;
      margin-left: -55px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #231815;
    }
  }

  /deep/ .page {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #231815;
    }

    .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: rgb(255, 255, 255);
    }
  }

  .read {
    background: url("../assets/readdoc.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .read:hover {
    background: url("../assets/readhover.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .down {
    background: url("../assets/downfile.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .del {
    background: url("../assets/delfile.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .mail {
    width: 31px;
    height: 31px;
    background: url("../assets/mail-button.png") no-repeat;
  }

  .mail:hover {
    background: url("../assets/mail-button-click.png") no-repeat;
  }

  .down:hover {
    background: url("../assets/downhover.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .del:hover {
    background: url("../assets/delhover.png") no-repeat;
    height: 31px;
    width: 31px;
  }

  .list-main-head {
    width: 100%;
    height: 55px;
    background: #F5F5F5;
    display: flex;
    justify-content: center;

    .list-main-head-info {
      cursor: pointer;
      width: 1200px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 56px;

      .tip {
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  /deep/ .list-main-content {
    width: 100%;
    margin-top: 30px;

    .el-table .descending .sort-caret.descending {
      border-top-color: #231815;
    }

    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: #231815;
    }

    .el-icon-arrow-down:before {
      content: url("../assets/sanjiao.png");
    }
  }
}
</style>

<style>
.el-table tr {
  height: 51px;
}

.el-table .el-table__cell {
  padding: 0;
}

.el-table th.el-table__cell > .cell {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.el-table th.el-table__cell > .cell.highlight {
  color: #231815;
}

.el-table-filter__list-item {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.el-table-filter__list-item:hover {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFF;
  background-color: #231815;
}

.el-table-filter__list-item.is-active {
  background-color: #231815;
  color: #FFF;
}
</style>